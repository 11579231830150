<template>
    <div class="bankcard-page">
        <div class='bank-header'>您的信息安全将严格被保护，请安心填写</div>
        <div class="bank-main" v-if='userInfo!=null'>
            <div class='bank-line'>
                <span class='bank-line-name'>姓名</span>
                <!-- :value='userInfo.nickName'value="赵达" -->
                <input class='bank-input' placeholder="姓名"  :value="userInfo.nickName" disabled="true"/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>身份证号</span>
                <!-- :value="userInfo.certNo" -->
                <input class='bank-input bank-i-card'  placeholder="身份证号" :value="userInfo.certNo" disabled="true"/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>银行卡号</span>
                <input class='bank-input bank-i-card' v-model="bankVal" placeholder="请输入银行卡号" @change="changeBank($event)"/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>手机号</span>
                <input class='bank-input bank-input-number' placeholder="请输入该银行卡的预留手机号码" v-model="telNum" @change="changeInput($event)"/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>验证码</span>
                <input class='bank-input bank-i-send' placeholder="请填写验证码" v-model="verifyCode" @change='changeCode($event)' type='number' oninput="if(value.length>6)value=value.slice(0,6)"/>
                <span class='bank-sendc' @click='GetVerifyCode' v-show="canSendCode">{{ verifyTxt}}</span>
                <span class='bank-sendc' @click='GetVerifyCode' v-show="!canSendCode">{{countdown}}秒</span>
            </div>
        </div>
        <div class='bank-btn'>
             <div :class='bankActive ? "bank-active" : "bank-b" ' @click='bindBank'>
                绑定银行卡 
            </div>
        </div>
        <div class='bank-footer' @click='supportBank'>
            <div class="bank-footer-icon"></div>
            <div class="bank-footer-text" >查看支持银行卡</div>
        </div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import {Toast} from 'vant';
import {routerTag} from '../../utils/index'

export default ({
    setup() {
        const state=reactive({
            bankActive:false,
            userInfo:null,//用户的基本信息
            telNum:'',
            verifyCode: '',
            countdown: 60,
            verifyTxt: "点击获取验证码",
            canSendCode: true,
            bankVal:''//银行卡号
        })
        const init=async()=>{
            const result=await API.bankInit({})
            // APP.Alert(JSON.stringify(result))
            state.userInfo=result.user
            console.log(result,88);
        }
        const changeBank=(e)=>{
            console.log(e.target.value,33);
            state.bankVal=e.target.value
        }
        //输入手机号获取值
        const changeInput=(e)=>{
            console.log(e.target.value,33);
        
            state.telNum=e.target.value;
            
        }
          //获取验证码的input输入值
        const changeCode=(e)=>{
            console.log(e.target.value,33);
            if(e.target.value=='' || e.target.value.length > 6){
                Toast('请输入正确的验证码')
                return false
            }
            state.verifyCode=e.target.value;
        
        }
           //获取验证码
        const GetVerifyCode= async ()=>{
             window.TDAPP.onEvent("银行卡绑卡-点击获取验证码-进入");
            const {telNum,userInfo,bankVal}=state
            const prodNo=window.localStorage.getItem('appNo');
            const orderNo=window.localStorage.getItem('orderNo')
            if(bankVal==''){
                Toast('请输入银行卡号')
                return false;
            }
            if(telNum==''){
                Toast('请输入手机号')
                return false;
            }
            const result= await API.bankSms({
                appNo:prodNo,//产品Id
                orderNo:orderNo,//订单编号
                ownerName: userInfo.nickName,//姓名
                ownerIdNo: userInfo.certNo,//身份证
                bankAccount: bankVal,//卡号
                mobile: telNum, //预留手机号
                cardType:1 ,//默认是1
                scene :1 //默认是1
            })
            if(result.needVerify==0){
                Toast(result.remark)
                state.bankActive=false;
                return false;

            }else{
                countdowns()
                state.bankActive=true;
            }
         
        } 
        // 验证码倒计时
        const countdowns=() =>{
            console.log(111);
           const TIME_COUNT = 60;
            if (!state.timer) {
                state.countdown = TIME_COUNT;
                state.canSendCode = false;
                state.timer = setInterval(() => {
                    if (state.countdown > 0 && state.countdown <= TIME_COUNT) {
                        state.countdown--;
                    } else {
                        state.canSendCode = true;
                        clearInterval(state.timer);
                        state.timer = null;
                    }
                }, 1000)
            }
        }
        //绑卡
        const bindBank= async ()=>{
            window.TDAPP.onEvent("银行卡绑卡-进入");
            if(state.bankActive==false){
                return false;
            }
            const {telNum,userInfo,bankVal,verifyCode}=state
            const prodNo=window.localStorage.getItem('appNo');
            const orderNo=window.localStorage.getItem('orderNo')
            if(state.verifyCode==''){
                 Toast('请先输入验证码')
                return false;
            }
            const result = await API.bankBind({
                appNo:prodNo,//产品Id
                orderNo:orderNo,//订单编号
                ownerName: userInfo.nickName,//姓名
                ownerIdNo: userInfo.certNo,//身份证
                bankAccount:bankVal,//卡号
                mobile: telNum, //预留手机号
                cardType:1, //默认是1
                scene :1, //默认是1
                validCode: verifyCode //验证码
            })
            if(result.needVerify==0){
                APP.GO_BACK(1)
            }else{
                Toast('绑卡失败')
                return false;
 
            }
           
        }
        const supportBank=()=>{
            //支持银行卡页
            const supportUrl=routerTag+"supportbank"
            APP.JUMP_H5(supportUrl,'支持的银行卡') 
            
        }
        onMounted(()=>{
            APP.SET_TITLE('添加银行卡')
            init()
        })
        return{
            ...toRefs(state),
            init,
            changeInput,
            changeCode,
            GetVerifyCode,
            bindBank,
            supportBank,
            changeBank
        }
    },
})
</script>